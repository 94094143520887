.get-started-item {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.get-started-number {
    background-color: rgb(26.7%, 61.6%, 82%, 20%);
    color: #000;
    padding: 10px;
    margin-right: 4px;
    border-radius: 5px 0px 0px 5px;
    font-weight: 600;
}

.get-started-text {
    background-color: rgb(26.7%, 61.6%, 82%, 20%);
    color: #000;
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
    width: 100%;
    align-self: center;
}

@media (max-width: 992px) {
    .description-image {
        width: 420px
    }
}

@media (max-width: 768px) {
    .description-image {
        width: 350px
    }
}

@media (max-width: 576px) {
    .description-image {
        width: 330px;
    }
}

@media (min-width: 992px) {
    .description-image {
        width: 450px;
    }
}

@media (max-width: 768px) {
    .video-player {
        width: 500px;
        height: 315px;
    }
}

@media (max-width: 576px) {
    .video-player {
        width: 300px;
        height: 200px;
    }
}

@media (min-width: 768px) {
    .video-player {
        width: 560px;
        height: 315px;
    }
}