.override_input {
    border: none;
    background-color: transparent;
    color: #fff;
}

.result_title {
    color: #fff;
    font-size: large;
    word-wrap: break-word;
}

.white_text {
    color: #fff;
}

.large_text {
    font-size: 20pt;
}

.center_text {
    text-align: center;
}

.block {
    display: block;
}

.results {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 100%;
}

.result {
    background-color: #264653;
    border-radius: 5px;
    padding: 15px;
    text-align: center;
}

#calculator {
    display: flex;
    flex-direction: column;
    text-align: center;
    border: 3px solid #264653;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px !important;
}

.calculator_field {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: end;
    margin-top: 3px;
    padding-bottom: 3px;
}

.calculator_input {
    border: 1px none none solid none #264653;
}

.slider_and_entry {
    display: flex;
}

@media screen and (max-width: 768px) {
    .results {
        flex-direction: column;
        max-width: 100%;
    }

    .result_right {
        margin-top: 10px;
    }

    .slider_and_entry {
        flex-direction: column;
    }

    .calculator_field {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 10px;
    }
}

@media screen and (min-width: 768px) {
    .result_right {
        margin-left: 5px;
    }

    .result_left {
        margin-right: 5px;
    }
}