$colors: shapes-primary-color("span-1-bg"), shapes-primary-color("span-2-bg"), shapes-primary-color("span-3-bg"), shapes-primary-color("span-4-bg"), shapes-primary-color("span-5-bg");
$process-card-count: 5;
$radius: 1.5rem;
$line-height: 1.5;
$line-way-width: 3rem;
$dot-size: 1.2rem;
$title-size: 2rem;
$process-card-height: 600px;

%dot {
    content: '';
    position: absolute;
    top: 50%;
    left: - $line-way-width / 2;
    z-index: 1;
    transform: translate(-50%, -50%);
    width: $dot-size;
    height: $dot-size;
    border-radius: 50%;
    background-color: inherit;
    box-shadow: inset 0 0 0 $dot-size/3.5;
    transition: all .5s;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pointer {
    cursor: pointer;
}

.process-cards {
    position: relative;
    height: $process-card-height;
    overflow: hidden;
}

.process-card {
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 100%;
    overflow: hidden;
    border-radius: $radius;
    color: #fff;
    transition: all .5s;
    background-image: linear-gradient(to right,
            transparent calc(1.5rem - 3px),
            #fff calc(1.5rem - 3px),
            #fff calc(1.5rem + 3px),
            transparent calc(1.5rem + 3px));
    counter-increment: process-card;

    &:nth-of-type(1) {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: $line-way-width / 2;
            z-index: 0;
            transform: translateX(-50%);
            height: $title-size;
            background-color: inherit;
        }
    }

    @for $i from 1 through $process-card-count {
        &:nth-of-type(#{$i}) {
            transform: translateY(calc(#{($i - 1)/$process-card-count} * (100% - #{$radius})));
        }

        &:nth-of-type(#{length($colors)}n + #{$i}) {
            background-color: nth($colors, $i);
        }
    }
}

.process-card_dark {
    color: #264653;
}

.process-cards.is-active {
    .process-card {
        transform: translateY(0) !important;

        &.is-active {
            transform: translateY(7.5%) !important;

            .process-card__title {
                transform: translateY(0);

                &::after {
                    transform: scale(1);
                }
            }

            .schedules {
                opacity: 1;
            }
        }

        &:nth-of-type(1).is-active {
            transform: translateY(0%) !important;
        }

        &.is-active~.process-card {
            transform: translateY(100%) !important;
        }

        &.is-active+.process-card {
            transform: translateY(calc(92.5% - #{$radius})) !important;
        }
    }

    .process-card__title {
        transform: translateY(-25%);

        &::after {
            transform: scale(.6);
        }
    }
}

.process-card__title {
    position: relative;
    margin: 0 1.5rem .25em $line-way-width;
    padding-top: 1rem;
    background-color: inherit;
    font-size: $title-size;
    font-weight: 900;
    text-transform: capitalize;
    cursor: pointer;
    transition: transform .3s;

    color: #fff;

    &::after {
        display: block;
        transition: transform .3s;
        transform-origin: 0% 50%;
    }

    &::before {
        @extend %dot;
        top: calc(50% + .5rem);
        box-shadow: inset 0 0 0 $dot-size/3.5;
        color: #fff;
    }

    .process-card.is-active &::before {
        box-shadow: inset 0 0 0 $dot-size;
    }
}

.process-card__title_dark {
    color: #264653;
}

.schedules {
    background-color: inherit;
    max-height: calc(100% - #{$title-size*$line-height + 1rem});
    background-image: inherit;
    padding-left: $line-way-width;
    padding-right: 1.5rem;
    padding-bottom: $process-card-height * .15 + $radius * 16px / 1rem;
    overflow: auto;
    transition: opacity .3s;
    opacity: 0.1;
}

.schedules__time {
    position: relative;
    margin-bottom: .25rem;
    background-color: inherit;
    font-size: large;
    font-weight: 800;

    &::before {
        @extend %dot;
        font-size: 1.6em;
        box-shadow: none;
        transform: translate(-50%, -50%) scale(0);
        transition-delay: .3s;
    }

    &::after {
        @extend %dot;
        width: .6em;
        height: .6em;
        box-shadow: inset 0 0 0 $dot-size;
        transform: translate(-50%, -50%) scale(0);
        transition-delay: .5s;
    }

    .process-card.is-active & {

        &::before,
        &::after {
            transform: translate(-50%, -50%) scale(1);
            transition-delay: .3s;
        }

        &::after {
            transition-delay: .35s;
        }
    }
}

.schedules__info {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: medium;
        font-weight: 500;
        width: 80%;
        text-align: left;
    }
}

.box {
    margin: 0 auto;
    font-family: sans-serif;
    line-height: $line-height;
    border-radius: $radius;
    box-shadow: 0 1.5rem 6rem -1rem rgba(#000, .3);

    img {
        max-width: 100%;
        height: auto;
    }
}

.intro {
    max-width: 30rem;
    padding-bottom: 1rem;
    margin: 0 auto 1em;
    padding-top: .5em;
    text-align: center;
    text-transform: capitalize;
    border-bottom: 1px dashed rgba(#000, .3);
    text-shadow: 0 1px 1px rgba(#fff, .8), 0 2px 5px rgba(#000, .3);

    small {
        display: block;
        opacity: .5;
        font-style: italic;
        text-transform: none;
    }
}

.info {
    position: absolute;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 1em;
    font-size: .9em;
    font-style: italic;
    font-family: serif;
    text-align: right;
    opacity: .5;

    a {
        color: inherit;
    }
}