.main-form-border {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding-top: 15px;
    border-radius: 5px;
    background-color: #f2f2f2;
    color: shapes-primary-color("step-1-gradient-bg");
    transition: .5s;
    transition: height 1s ease;

    &:hover,
    &:focus {
        transform: scale(1.01);
        box-shadow: 0 0 15px shapes-primary-color("step-1-gradient-bg");
        box-shadow: 0 0 5px rgb(58, 57, 52);
    }
}

.valid-input-check {
    background-image: url(
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"
    ) !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-input-caution {
    background-image: url(
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e"
     ) !important;
}

.hide-spin {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0;
        /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance: textfield;
        /* Firefox */
    }
}

.hero-image {
    @media (min-width: 992px) {
        .container {
            text-align: center;
        }
    }

    @media (min-width: 768px) {
        .container {
            margin-top: 10px;
        }
    }
}

.form-title {
    text-align: center;
    font-size: $font-size-xxl;
    font-weight: 900;
}

.form-subtitle {
    color: theme-color("default");
    font-size: $font-size-sm;
    text-align: center;
    padding: 0px 20px;
}

.custom-control-label {

    // Background-color and (when enabled) gradient
    &::before {
        border: $custom-control-indicator-border-width solid $custom-control-indicator-border-color;

        @if $enable-transitions {
            transition: all .3s ease;
            ;
        }
    }

    span {
        position: relative;
        top: 2px;
    }
}

.custom-control {
    padding-left: 1.7rem !important;
}

.custom-control-label {
    margin-bottom: 0;

    &:before,
    &:after {
        left: -1.75rem;
    }
}

.custom-control-input {
    &:active~.custom-control-label::before {
        border-color: $custom-control-indicator-active-border-color;
    }
}

// Alternative custom control
.custom-control-alternative {
    .custom-control-label {

        // Background-color and (when enabled) gradient
        &::before {
            border: 0;
            box-shadow: $input-alternative-box-shadow;
        }
    }

    .custom-control-input {
        &:checked {
            ~.custom-control-label {
                &::before {
                    box-shadow: $input-focus-alternative-box-shadow;
                }
            }
        }

        &:active~.custom-control-label::before,
        &:focus~.custom-control-label::before {
            box-shadow: $input-alternative-box-shadow;
        }
    }
}


// Checkboxes
.custom-checkbox {
    .custom-control-input~.custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~.custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }

                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~.custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}

// Radios
.custom-radio {
    .custom-control-input~.custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~.custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }

                &::after {
                    background-image: $custom-radio-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~.custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }

}

// Toggles
.custom-toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 1.5rem;

    input {
        display: none;

        &:checked {
            +.custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-checked-border-color;

                &:before {
                    background: $custom-toggle-checked-bg;
                    transform: translateX(1.625rem);
                }
            }
        }

        &:disabled {
            +.custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;
            }

            &:checked {
                +.custom-toggle-slider {
                    border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;

                    &:before {
                        background-color: lighten($custom-control-indicator-checked-bg, 10%);
                    }
                }
            }
        }
    }
}

.custom-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $custom-control-indicator-border-width solid $input-border-color;
    border-radius: 34px !important;
    background-color: transparent;


    &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 2px;
        bottom: 2px;
        border-radius: 50% !important;
        background-color: $custom-toggle-slider-bg;
        transition: $input-transition;
    }
}