.form-success-wrapper {
    width: 100%;
    margin: 2em 0 4em;
}

.form-success-checkmark {
    stroke: theme-color("primary");
    stroke-dashoffset: 745.74853515625;
    stroke-dasharray: 745.74853515625;
    animation: dash 3s ease-out forwards;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 745.74853515625;
    }

    100% {
        stroke-dashoffset: 0;
    }
}