.faq-number-badge {
    display: inline-block;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    text-align: center;
    background: theme-color("secondary");
    color: #fff;
    font-size: 12px;
    margin-right: 20px;
}